.orderSchedulerView {
  .schedulerContainer {
    &.week {
      // hide time scale
      .MuiGrid-grid-xs-1 {
        display: none;
      }
      .MuiGrid-grid-xs-11 {
        max-width: 100%;
        flex-basis: 100%;
      }
    }
  }
}
