$primaryColor: #57d599;
$secondaryColor: #827ce3;

.loginView {
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: #333333;
  form {
    display: inline-block;
    width: 100%;
    max-width: 400px;
    padding: calc(40vh - 160px) 25px;
    text-align: left;
    .logo {
      width: 100%;
      height: 60px;
      background-image: url(/img/logo.png);
      background-size: 240px;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 25px;
    }
    label {
      display: block;
      color: #ffffff;
      margin-bottom: 2px;
    }
    input {
      display: block;
      margin-bottom: 7px;
      width: 100%;
      border: none;
      padding: 7px 11px;
      outline-color: $secondaryColor;
      border-radius: 4px;
      &::placeholder {
        color: #F44336;
      }
    }
    button, button:hover {
      width: 100%;
      background-color: $primaryColor;
      color: #ffffff;
      border: none;
      padding: 7px 21px;
      margin: 10px 0px;
      outline-color: $secondaryColor;
      text-transform: uppercase;
      border-radius: 4px;
    }
    span {
      color: #cccccc;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
