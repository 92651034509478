$primaryColor: #57d599;
$secondaryColor: #827ce3;
$errorColor: #fa755a;

.supportView {
	width: 100%;
	min-height: 100vh;
	text-align: center;
  background-color: #eaeff1;
  .header {
    text-align: center;
    width: 100%;
    height: 54px;
    background-color: rgb(35, 47, 62);
    box-shadow: rgb(64, 72, 84) 0px -1px 0px inset;
    .logo {
      width: 125px;
      height: 54px;
      background-size: 112px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(/img/logo.png);
      display: inline-block;
      cursor: pointer;
    }
  }
	.supportForm {
    display: inline-block;
    width: 100%;
		max-width: 480px;
		padding: 10px 15px 25px;
		h1 {
			margin: 25px 0px;
      text-align: center;
		}
		input, textarea {
      display: block;
      margin-bottom: 7px;
      width: 100%;
      border: 1px solid #eeeeee;
      padding: 7px 11px;
      outline-color: $secondaryColor;
      border-radius: 4px;
      font-size: 15px;
      box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);
      &.error {
        border-color: $errorColor;
      }
    }
    textarea {
      resize: none;
      height: 100px;
    }
    button {
      margin-top: 5px;
      height: 37px;
      background-color: $primaryColor;
      color: #ffffff;
      font-size: 15px;
      text-transform: uppercase;
      width: 100%;
      border: none;
      border-radius: 4px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }
	}
}
