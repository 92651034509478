.adminContent.wizardContent {
  font-size: 14px;
  font-weight: 400;
  b {
    font-weight: 500;
  }
  .wizardHeader {
    background-color: #f2f2f2;
    h1 {
      margin: 0px;
      padding: 20px 0px 25px;
      text-align: center;
      font-weight: 500;
      font-size: 36px;
    }
  }
  form {
    padding: 0px 20px 10px;
    .backLink {
      display: block;
      padding: 10px 0px;
      color: #337ab7;
      text-decoration: none;
      cursor: pointer;
      .fa {
        font-size: 8px;
        margin-right: 8px;
        vertical-align: top;
        padding-top: 7px;
      }
      &:hover {
        color: #23527c;
        text-decoration: underline;
      }
    }
    label {
      font-weight: 500;
      padding: 11px 15px;
      margin: 0px;
    }
    .outerLabel {
      font-weight: 500;
      padding: 2px 5px 16px;
    }
  }
  .panel {
    border: none;
    border-radius: 0px;
    margin: 0px;
    box-shadow: none;
    border-top: 1px solid #cccccc;
    .panel-heading {
      border: none;
      border-radius: 0px;
      padding: 0px;
      background-color: transparent;
      .panel-title {
        margin: 0px;
        font-size: 14px;
        font-weight: 600;
        font-size: inherit;
        color: #4a90e2;
        a {
          display: block;
          padding: 15px 30px 15px 15px;
          position: relative;
          cursor: pointer;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
          &:after {
            content: "+";
            padding-top: 2px;
            display: block;
            position: absolute;
            right: 15px;
            top: -1px;
            line-height: 48px;
            font-weight: 100;
            font-size: 26px;
            width: 15px;
            text-align: center;
          }
          &:not(.collapsed) {
            &:after {
              content: "-";
              padding-top: 0px;
            }
          }
        }
      }
    }
    .panel-body {
      position: relative;
    }
    .panel-collapse {
      .panel-body {
        padding: 5px 0px 0px;
      }
    }
  }
  .panelError {
    margin-left: 15px;
    color: #F44336;
    font-size: 14px;
  }
  .partsTable {
    width: 100%;
    table-layout: fixed;
    th {
      padding: 0px 0px 10px;
    }
    td:not(:last-child) {
      padding: 0px 16px 0px 0px;
    }
    .deletePart {
      vertical-align: top;
      cursor: pointer;
      padding: 5px;
      color: #cccccc;
      float: right;
      margin: 0px 15px 15px 0px;
    }
  }
  .addPartButton {
    text-align: right;
    margin: 16px 16px 10px;
  }
  .questionInput {
    width: 100%;
    margin: 0px 0px 18px;
    position: relative;
    .inputError {
      position: absolute;
      top: 100%;
      font-size: 12px;
      color: #F44336;
    }
    input[type="text"], textarea {
      border: 1px solid #cccccc;
      padding: 9px 16px;
      box-shadow: none;
      border-radius: 0px;
      height: auto;
      &::placeholder {
        color: #cccccc;
      }
    }
    .ql-toolbar, .ql-container {
      border-radius: 0px;
      border: 1px solid #cccccc;
      button {
        box-shadow: none;
        border-radius: 0px;
      }
      .ql-editor.ql-blank::before {
        color: #cccccc;
        font-size: 14px;
        font-style: normal;
      }
    }
    .Select {
      .Select-control {
        border-radius: 0px;
        border-color: #cccccc;
        .Select-input {
          height: 38px;
        }
        .Select-placeholder {
          line-height: 38px;
          color: #cccccc;
        }
        .Select-value {
          line-height: 38px;
        }
        
      }
      .Select-menu-outer {
        border-radius: 0px;
        border-color: #cccccc;
        .Select-option {
          
          &:last-child {
            border-radius: 0px;
          }
          &.is-selected {
            background-color: #315e94;
            color: #fff;
          }
          &.is-focused {
            background-color: #4a90e2;
            color: #fff;
          }
        }
      }
    }
    .Select--multi {
      min-height: 40px;
      .Select-control {
        padding-right: 40px;
        min-height: 40px;
        table-layout: fixed;
        .Select-multi-value-wrapper {
          max-width: 100%;
        }
        .Select-value {
          margin: 6px 0px 0px 7px;
          line-height: 20px;
          border-radius: 0px;
          background-color: transparent;
          border: 1px solid #315e94;
          padding-left: 19px;
          position: relative;
          max-width: 100%;
          .Select-value-icon {
            color: #4a90e2;
            border-color: #315e94;
          }
          .Select-value-label {
            color: #315e94;
            overflow: hidden;
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .Select-value-icon {
            position: absolute;
            left: 0px;
            right: 0px;
            width: 19px;
          }
        }
        .Select-arrow-zone {
          position: absolute;
          right: 0px;
          top: calc(50% - 10px);
        }
      }
    }
  }
  .inputColumn {
    .questionInput {
      margin: 0px 8px 13px;
      width: calc(100% - 16px);
    }
  }
  .answersHint {
    padding: 8px 0px 12px;
  }
  .panelActions {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 6px 2px 6px 0px;
    .actionButton {
      display: inline-block;
      cursor: pointer;
      color: #cccccc;
      text-decoration: underline;
      font-weight: 400;
      &:hover {
        color: #aaaaaa;
      }
      a {
        padding: 0px;
        &:after {
          content: none;
          display: none;
        }
      }
    }
    .titleSeparator {
      color: #cccccc;
      font-weight: 400;
    }
  }
  .answersList {
    text-align: right;
    padding-bottom: 5px;
    .answerRow {
      text-align: left;
      .correctIndicator {
        display: inline-block;
        vertical-align: top;
        position: relative;
        cursor: pointer;
        padding: 10px 10px 10px 0px;
        margin-right: 5px;
        &::before {
          content: " ";
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #cccccc;
        }
        &::after {
          content: " ";
          display: block;
          position: absolute;
          top: 20px;
          left: 10px;
          width: 0px;
          height: 0px;
          border-radius: 50%;
          background-color: #4a90e2;
          transition: all 200ms ease-in-out;
        }
        &.checked::after {
          top: 15px;
          left: 5px;
          width: 10px;
          height: 10px;
        }
      }
      .answerCheckbox {
        vertical-align: top;
        display: inline-block;
        width: 20px;
        .selectIndicator {
          margin-right: 0px;
          padding: 10px 0px;
        }
      }
      .answerPoints {
        vertical-align: top;
        display: inline-block;
        width: 100px;
        margin: 0px 16px;
      }
      .answerText {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 30px);
      }
      .caseText {
        vertical-align: top;
        display: inline-block;
        width: calc(50% - 35px);
        &+i {
          font-size: 26px;
          line-height: 36px;
          margin: 0px -5px 0px 2px;
          color: #315e94;
        }
        .questionInput {
          margin-right: 10px;
          width: calc(100% - 25px);
        }
      }
      .answerSelect {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 30px);
        .questionInput {
          margin-bottom: 20px;
        }
      }
      .deleteAnswer {
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        padding: 7px 0px 4px 5px;
        color: #cccccc;
      }
    }
  }
  .categoriesList {
    .quizCategory {
      display: inline-block;
      width: 37.5%;
      &:nth-child(3n) {
        width: 25%;
      }
      .selectIndicator {
        display: inline-block;
        vertical-align: top;
        position: relative;
        cursor: pointer;
        padding: 10px 10px 10px 0px;
        margin-right: 5px;
        &::before {
          content: " ";
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid #cccccc;
        }
        &::after {
          content: " ";
          display: block;
          position: absolute;
          top: 20px;
          left: 10px;
          width: 0px;
          height: 0px;
          background-color: #4a90e2;
          transition: all 200ms ease-in-out;
        }
        &.checked::after {
          top: 15px;
          left: 5px;
          width: 10px;
          height: 10px;
        }
      }
      .categoryText {
        display: inline-block;
        line-height: 40px;
      }
    }
  }
  .coefficientList {
    display: table;
    padding-bottom: 25px;
    .coefficientItem {
      display: table-row;
      label {
        display: table-cell;
      }
      input {
        display: table-cell;
      }
    }
  }
  .coefficientTable {
    margin-bottom: 25px;
    tr {
      td {
        input[type='text'] {
          width: 75px;
          margin: 0px 10px;
          text-align: center;
        }
        padding: 6px 8px;
        border-left: 1px solid #eeeeee;
        &:first-child {
          border-left: none;
        }
      }
      border-top: 1px solid #eeeeee;
      &:first-child {
        text-align: center;
        border-top: none;
      }
    }
  }
  .btn {
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #315e94;
    color: #eeeeee;
    font-size: 14px;
    width: auto;
    font-weight: 600;
    border-radius: 4px;
    padding: 8px 18px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px 0px;
    outline: none;
    &:hover {
      color: #ffffff;
    }
    &::selection, &-moz-selection {
      color: #ffffff;
    }
    i {
      font-size: 15px;
      vertical-align: top;
      padding-right: 5px;
      vertical-align: text-top;
    }
  }
  .plainButton {
    background-color: transparent;
    box-shadow: none;
    color: #cccccc;
    text-decoration: underline;
    font-weight: 400;
    outline: none;
    padding: 5px 0px;
    &:hover {
      color: #aaaaaa;
    }
  }
}

@media all and (max-width: 768px) {
  .adminContent.wizardContent {
    padding-left: 0px;
    padding-right: 0px;
    .page-header {
      margin-left: 0px;
      margin-right: 0px;
    }
    form {
      padding: 0px;
    }
    .partsList {
      border-top: 1px solid #aaaaaa;
      border-bottom: 1px solid #aaaaaa;
      padding: 18px 24px 12px;
      margin: -1px -24px;
      label {
        padding: 0px 0px 2px;
      }
      .questionInput {
        margin-bottom: 8px;
      }
    }
    .addPartButton {
      margin: 20px 0px 10px;
    }
  }
}
