$primaryColor: #57d599;
$secondaryColor: #827ce3;

.registerForm {
  width: 100%;
  p {
    margin: 0px 0px 6px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: -0.1px;
    color: #ffffff;
  }
  input[type='text'] {
    margin: 18px 0px 0px;
    width: 100%;
    border-radius: 10px;
    border: none;
    padding: 17px 19px 18px;
    color: #333333;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: -0.1px;
    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: -0.1px;
      color: #aaaaaa;
    }
  }
  input[type='button'] {
    margin: 18px 0px 0px;
    width: 100%;
    height: 63px;
    line-height: 61px;
    text-transform: uppercase;
    background-color: $primaryColor;
    text-align: center;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: -0.1px;
    text-align: center;
    color: #ffffff;
    -webkit-appearance: none;
  }
}

.landingView {
  font-size: 0px;
  width: 100%;
  position: relative;
  .headerButtons {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
    text-align: right;
    .headerButton {
      padding: 10px;
      display: inline-block;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      &:not(:first-of-type) {
        margin-left: 5px;
      }
    }
  }
  .storeButtons {
    width: 100%;
    margin-top: 38px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &>div {
      display: inline-block;
      width: calc(50% - 9px);
      height: 60px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }
    .appStore {
      background-image: url(/img/app_store.png);
    }
    .googlePlay {
      background-image: url(/img/google_play.png);
    }
  }
  .topSection {
    width: 100%;
    position: relative;
    padding: 86px 0px 130px;
    background-image: url(/img/topBackground.png);
    background-position: center;
    background-size: cover;
    .logo {
      background-image: url(/img/logo.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 200px;
      height: 42px;
      margin: 0 auto;
    }
    .givolaLogo {
      background-image: url(/img/givola_logo.png);
      background-size: 98px;
      background-repeat: no-repeat;
      background-position: 10px center;
      width: 125px;
      height: 30px;
      margin: 9px auto 0px;
      cursor: pointer;
    }
    .introBlock {
      margin: 85px auto 0px;
      text-align: center;
      .introText {
        text-align: left;
        display: inline-block;
        border-left: 12px solid $primaryColor;
        width: 525px;
        max-width: 100%;
        padding: 0px 25px 0px 23px;
        vertical-align: top;
        h1 {
          margin: 0px;
          font-size: 80px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 0.9;
          letter-spacing: -0.6px;
          color: #ffffff;
        }
        p {
          margin: 30px 0px 0px;
          font-size: 24px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: -0.2px;
          color: #ffffff;
        }
      }
      .introForm {
        display: inline-block;
        text-align: left;
        width: 525px;
        max-width: 100%;
        padding: 0px 110px 0px 60px;
        vertical-align: top;
      }
    }
    .videoButtonContainer {
      margin-top: 72px;
      text-align: center;
      padding-right: 100px;
      .videoButton {
        display: inline-block;
        width: 930px;
        max-width: calc(100% - 40px);
        padding: 27px;
        border-radius: 54px;
        background-color: rgba(0,0,0,0.45);
        cursor: pointer;
        label {
          margin: 0px;
          display: inline-block;
          vertical-align: top;
          font-size: 36px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 48px;
          letter-spacing: -0.3px;
          color: $primaryColor;
          cursor: inherit;
        }
        .playIcon {
          display: inline-block;
          vertical-align: top;
          width: 54px;
          height: 54px;
          margin-left: 26px;
          background-image: url(/img/play.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
  .contentRow {
    display: flex;
    flex-direction: row;
    &:nth-of-type(2n) {
      flex-direction: row-reverse;
    }
    .tileSection {
      width: 50%;
      height: 490px;
      display: inline-block;
      vertical-align: top;
    }
  }
  .imageSection {
    background-position: center;
    background-size: cover;
    &.illustration1 {
      background-image: url(/img/illustration1.png);
    }
    &.illustration2 {
      background-image: url(/img/illustration2.png);
    }
    &.illustration3 {
      background-image: url(/img/illustration3.png);
    }
  }
  .descriptionSection {
    background-color: #333333;
    padding: 100px 120px;
    text-align: center;
    &.primary {
      background-color: $primaryColor;
    }
    &.secondary {
      background-color: $secondaryColor;
    }
    .descriptionContent {
      width: 100%;
      max-width: 450px;
      text-align: left;      
      display: inline-block;
      .descriptionIcon {
        width: 50px;
        height: 50px;
        margin-bottom: 32px;
        background-position: left bottom;
        background-repeat: no-repeat;
        &.iconGraph {
          background-image: url(/img/icons/graph.png);
          background-size: 36px;
        }
        &.iconChecklist {
          background-image: url(/img/icons/checklist.png);
          background-size: 30px;
        }
        &.iconPhone {
          background-image: url(/img/icons/phone.png);
          background-size: 30px;
        }
      }
      .descriptionHeader {
        font-size: 30px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.07;
        letter-spacing: -0.2px;
        color: #ffffff;
      }
      .descriptionText {
        margin-top: 20px;
        font-size: 20px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.6;
        letter-spacing: -0.1px;
        color: #ffffff;
      }
    }
  }
  .bottomSection {
    width: 100%;
    background-color: $secondaryColor;
    text-align: center;
    padding: 105px 60px 170px;
    overflow: hidden;
    .logoBlock {
      width: 780px;
      max-width: 100%;
      display: inline-block;
      padding: 60px 40px 40px 210px;
      position: relative;
      text-align: left;
      .logo {
        position: absolute;
        left: -40px;
        top: 28px;
        width: 250px;
        height: 250px;
        max-width: 100%;
        background-image: url(/img/icon_shadow.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 280px;
        &::before {
          content: ' ';
          position: absolute;
          bottom: 20px;
          left: calc(50% - 60px);
          width: 120px;
          height: 24px;
          background-image: url(/img/logo_white.png);
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      .tagline {
        font-size: 80px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.9;
        letter-spacing: -0.6px;
        color: #ffffff;
      }
    }
    .introForm {
      width: 355px;
      display: inline-block;
      text-align: left;
      vertical-align: top;
    }
  }
}

@media all and (max-width: 1024px) {
  .landingView {
    .headerButtons {
      .headerButton {
        padding: 5px 10px;
      }
    }
    .topSection {
      padding: 85px 0px 90px;
      .introBlock {
        margin-top: 70px;
        padding: 0px 20px;
        .introText {
          h1 {
            font-size: 48px;
          }
          p {
            font-size: 22px;
          }
        }
        .introForm {
          margin-top: 75px;
          padding: 0px;
          width: 355px;
          max-width: 100%;
        }
      }
      .videoButtonContainer {
        padding-right: 0px;
        .videoButton {
          padding: 18px;
          label {
            font-size: 32px;
            line-height: 40px;
          }
          .playIcon {
            margin: 10px auto 0px;
            display: block;
          }
        }
      }
    }
    .descriptionSection {
      padding: 60px 35px;
      height: auto !important;
      .descriptionContent {
      }
    }
    .imageSection {
      height: 75vw !important;
    }
    .contentRow {
      flex-direction: column-reverse !important;
      .tileSection {
        width: 100%;
      }
    }
    .bottomSection {
      padding: 40px 0px 75px;
      .logoBlock {
        padding: 0px;
        text-align: center;
        .logo {
          position: relative;
          left: initial;
          top: initial;
          display: inline-block;
        }
        .tagline {
          font-size: 48px;
        }
      }
      .introForm {
        width: calc(100% - 40px);
        max-width: 355px;
        margin-top: 75px;
      }
    }
  }
  .registerForm {
    width: 100%;
    p {
    }
    input[type='text'] {
      &::placeholder {
      }
    }
    input[type='button'] {
    }
  }
}
