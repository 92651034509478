$primaryColor: #57d599;
$secondaryColor: #827ce3;
$errorColor: #fa755a;

body {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
	font-size: 16px;
	font-weight: 400;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	overscroll-behavior: none;
}

h1 {
	font-size: 32px;
	font-weight: 500;
	a, .backLink {
		text-decoration: none;
		margin: 0px 5px;
		padding: 2px 15px;
		color: #fafafa;
		text-decoration: none;
		cursor: pointer;
		&:hover {
			color: #ffffff;
			text-decoration: underline;
		}
	}
}

.form-horizontal {
	.form-group {
		margin-bottom: 18px;
	}
}

// Transition animation

.content-enter {
  opacity: 0.01;
	-webkit-transform: translate3d(-100%, 0, 0);
	transform: translate3d(-100%, 0, 0);
}
.content-enter.content-enter-active {
  opacity: 1;
	-webkit-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
  transition: all 400ms ease-in;
}
.content-leave {
  opacity: 1;
	-webkit-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}
.content-leave.content-leave-active {
  opacity: 0.01;
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
	transition: all 400ms ease-in;
} 
.listElem-enter {
  opacity: 0.25;
	-webkit-transform: translate3d(0, -35%, 0) scaleY(0.8);
	transform: translate3d(0, -35%, 0) scaleY(0.8);
}
.listElem-enter.listElem-enter-active {
  opacity: 1;
	-webkit-transform: translate3d(0, 0, 0) scaleY(1);
	transform: translate3d(0, 0, 0) scaleY(1);
  transition: all 300ms ease-in;
}
.listElem-leave {
	-webkit-transform: translate3d(0, 0, 0) scaleY(1);
	transform: translate3d(0, 0, 0) scaleY(1);
  opacity: 1;
}
.listElem-leave.listElem-leave-active {
  opacity: 0.25;
	-webkit-transform: translate3d(0, -35%, 0) scaleY(0.8);
	transform: translate3d(0, -35%, 0) scaleY(0.8);
	transition: all 300ms ease-in;
} 

.customScrollBar {
	-webkit-overflow-scrolling: touch;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background-color: #eee;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #ddd;
		border-radius: 2px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background-color: #ccc;
	}
}

.adminContent {
	position: absolute;
	overflow-y: auto;
	top: 54px;
	height: calc(100vh - 54px);
	vertical-align: top;
	display: inline-block;
	width: calc(100% - 260px);
	margin-left: 260px;
	padding: 30px 25px 80px;
	background-color: rgb(234, 239, 241);
	-webkit-overflow-scrolling: touch;
	.page-header {
		padding: 4px 25px 12px;
    margin: -30px -25px 30px;
		background-color: #009be5;
		color: #ffffff;
		border: none;
		font-size: 26px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.5px;
	}
	input[type="text"]+input[type="text"] {
		margin-top: -1px;
	}
	.card {
		padding: 32px 16px 20px;
		border-radius: 4px;
		box-shadow:
			0px 1px 3px 0px rgba(0,0,0,0.2),
			0px 1px 1px 0px rgba(0,0,0,0.14),
			0px 2px 1px -1px rgba(0,0,0,0.12);
		background-color: #ffffff;
	}
	.search-view {
		margin-bottom: 30px;
		padding: 16px;
		background-color: #ffffff;
		.searchField {
			margin-bottom: 4px;
			width: calc(33.3% - 12px);
			display: inline-block;
			vertical-align: top;
			&:not(:nth-child(3n+1)) {
				margin-left: 18px;
			}
			label {
				margin: 2px 0px;
				font-weight: 500;
			}
			&>input[type="text"] {
				display: inline-block;
				height: 36px;
				width: 100%;
			}
		}
		.rdt {
			input[type="text"] {
				height: 36px;
			}
		}
		.searchButton {
			padding: 9px 0px 1px;
			text-align: right;
		}
		input[type="checkbox"] {
			display: block;
		}
		.Select {
			width: 100%;
		}
		input[type="button"] {
			width: auto;
			height: 35px;
		}
		.date-interval {
			display: inline-block;
			width: 100%;
			.rdt {
				display: inline-block;
				width: calc(50% - 5px);
				&:nth-child(1) {
					margin-right: 10px;
				}
				.form-control {
					margin: 0px;
				}
			}
		}
		.interval {
			display: inline-block;
			width: 100%;
			.form-control {
				width: calc(50% - 5px);
				&:nth-child(1) {
					margin-right: 10px;
				}
			}
		}
		.address-search {
			display: inline-block;
			width: 100%;
			input[type="text"] {
				width: calc(25% - 5px);
				margin-left: 5px;
				&:nth-child(0) {
					margin-left: 0px;
				}
			}
		}
	}
	.listTabsView {
		margin-left: -1px;
		z-index: 2;
	}
	.listTabsView+.card {
		margin-top: -1px;
		border-radius: 0px 0px 4px 4px;
	}
	.Select-menu-outer {
		z-index: 3;
	}
	.input-group {
		input[type='hidden']+.form-control:not(:first-child):not(:last-child) {
			border-radius: 5px 0px 0px 5px;
		}
	}
	.inputField {
		position: relative;
	}
	.inputError {
		position: absolute;
		top: 100%;
		font-size: 12px;
		color: #F44336;
	}
	textarea {
		resize: vertical;
		height: 5.25em;
	}
	.view-link {
		font-size: 16px;
		line-height: 24px;
    padding: 5px 2px 0px;
		display: inline-block;
		&.clickable {
			color: #337ab7;
			cursor: pointer;
			&:hover {
				color: #23527c;
				text-decoration: underline;		
			}
		}
	}
	.edit-image {
		>span {
			line-height: 35px;
		}
		img {
			max-width: 180px;
			max-height: 120px;
			display: inline-block;
		}
		input[type="file"] {
			display: none;
		}
		input[type="button"] {
			display: block;
			padding: 6px 12px;
			color: #ffffff;
			background-color: rgba(100, 180, 180, 0.75);
		}
	}
	.edit-file {
		a {
			display: block;
			padding: 8px 0px;
		}
		input {
			display: block;
			padding: 10px 0px;
		}
	}
	.edit-video {
		max-width: 500px;
		border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
		input {
			margin: 16px 10px 12px;
		}
	}
	.ql-toolbar {
		border-radius: 5px 5px 0px 0px;
	}
	.ql-container {
		border-radius: 0px 0px 5px 5px;
		&.disabled {
			border-radius: 5px;
			background-color: #f5f5f5;
			cursor: not-allowed;
		}
	}
	.sortable {
		div {
			display: block;
			cursor: pointer;
			color: #333;
			border: 1px solid #ccc;
			border-radius: 5px;
			font-size: 16px;
    	margin: 10px 0px;
    	padding: 6px 16px;
			background-color: #eee;
			box-shadow: inset 0px 3px 5px rgba(255,255,255,0.5);
			&.sortable-chosen {
				background-color: #c0c0d0 !important;
			}
		}
	}
	.table {
		table-layout: fixed;
		width: 100%;
		margin-bottom: 10px;
		border: none;
		border-collapse: separate;
		font-size: 15px;
		thead {
			tr {
				th {
					height: 40px;
					border: none;
					color: #999999;
					font-weight: 400;
					cursor: default;
					&.sortable {
						cursor: pointer;
						&:hover {
							background-color: rgba(0,0,0,0.08);
						}
					}
					input[type='checkbox'] {
						margin-left: 3px;
					}
				}
			}
		}
		tbody {
			tr {
				td {
					padding: 10px;
					max-width: calc(100vw - 800px);
					overflow: hidden;
					white-space: nowrap;
					&.highlight {
						box-shadow: inset 0px 0px 4px rgba(255, 0, 0, 0.45), 0px 0px 2px rgba(255, 0, 0, 0.45);
					}
				}
			}
		}
		.btn {
			margin: -8px 0px -5px;
			padding: 4px 11px;
		}
	}
	.button-delete {
		color: #ffffff;
		background: rgba(200, 60, 60, 0.75);
	}
	.button-edit {
		color: #ffffff;
		background: rgba(80, 180, 80, 0.75);
	}
	.button-add {
		float: right;
		margin: 0px 11px;
		background-color: rgba(100, 100, 200, 0.75);
		color: #ffffff;
	}
	.button-fast-add {
		float: right;
		margin: 8px 0px 0px;
		background-color: rgba(100, 100, 200, 0.75);
		color: #ffffff;
	}
	.button-search {
		color: #ffffff;
		background: rgba(180, 80, 180, 0.75);
	}
	.button-export {
		float: left;
		margin: 5px;
		background-color: rgba(100, 180, 180, 0.75);
		color: #ffffff;
		width: 145px;
	}
	.button-request {
		display: inline-block;
		font-size: 16px;
		text-align: left;
		margin: 10px 12px;
    	padding: 6px 14px;
		width: 220px;
	}
	.button-report {
		margin: 5px;
		background-color: rgba(100, 180, 180, 0.75);
		color: #ffffff;
	}
	.reportButtons {
		position: relative;
    top: 38px;
		right: -5px;
    float: right;
	}
	.pagination {
		width: calc(100% - 115px);
		margin: 12px 0px 0px;
		li {
			a, a:focus {
				color: $primaryColor;
				outline: none;
			}
		}
		.disabled {
			a, a:focus {
				color: #777777;
			}
		}
		.active, .active:hover {
			a, a:focus {
				color: #ffffff;
				background-color: $primaryColor;
				border-color: $primaryColor;
			}
		}
	}
	.Select {
		width: 100%;
		display: inline-block;
		vertical-align: middle;
		input {
			border: none;
			box-shadow: none;
		}
		.Select-control {
			.Select-multi-value-wrapper {
				white-space: normal;
			}
		}
	}
	.Select.is-disabled .Select-control, .form-control[disabled], .form-control[readonly] {
		cursor: not-allowed;
		background-color: #f5f5f5;
	}
	div {
		input[type="checkbox"] {
			vertical-align: bottom;
			margin-top: 0px;
			margin-bottom: -5px;
		}
	}
	.options-table {
		width: 100%;
		vertical-align: top;
		max-height: 420px;
		input[type="text"] {
			width: 100%;
			padding: 4px;
			border-color: rgba(125,125,125,0.25);
		}
		.delete-row {
			text-align: center;
			vertical-align: middle;
			background-color: rgba(255,0,0,0.2);
			&:hover {
				background-color: rgba(255,0,0,0.4);
			}
		}
		.add-row {
			text-align: center;
			vertical-align: middle;
			background-color: rgba(0,255,0,0.2);
			&:hover {
				background-color: rgba(0,255,0,0.4);
			}
		}
		tbody {
			td {
				overflow: visible;
			}
		}
	}
	.exportBox {
		display: inline-block;
		width: 80%;
		max-width: 620px;
		margin: 25px 0px 0px;
		input[type="text"] {
			width: 70%;
			display: inline-block;
			float: left;
		}
		.Select {
			width: calc(70% + 121px);
			margin-bottom: 6px;
		}
	}
}

@media all and (max-width: 768px) {
	.adminContent {
		width: 100%;
		margin-left: 0px;
		.search-view {
			.searchField {
				width: 100%;
				&:not(:nth-child(3n+1)) {
					margin-left: 0px;
				}
			}
			.searchButton {
				padding: 16px 0px 8px;
			}
		}
		.table {
			border-top: 1px solid #999999;
			tbody {
				tr {
					td {
						white-space: normal;
						&:first-child {
							color: #999999;
							padding-left: 10px;
						}
					}
				}
			}
		}
		.table+.table {
			margin-top: 50px;
		}
	}
}

.defectsInput {
	table {
		td, th {
			text-align: left !important;
		}
	}
}

.infoLabel {
	position: absolute;
	display: inline-block;
	background-color: #666;
	color: #ffffff;
	border: 1px solid #ffffff;
	padding: 4px;
	border-radius: 10px;
	width: 80px;
	text-align: center;
}

.copyable {
	input {
		width: calc(100% - 50px);
		display: inline-block;
	}
	button {
		float: right;
	}
}

.createLink {
	position: absolute;
	font-weight: 500;
	font-size: 16px;
	padding: 20px 0px;
	span {
		color: #1c91d7;
		text-decoration: underline;
		cursor: pointer;
		i {
			text-decoration: underline;
			font-size: 12px;
		}
	}
}

.selectInput {
	outline: none;
  .selectInput__control {
    background-color: #ffffff;
		border: 1px solid #cccccc;
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
		min-height: 36px;
		&.selectInput__control--is-disabled {
			cursor: not-allowed;
			background-color: #f5f5f5;
			.selectInput__value-container {
				.selectInput__single-value {
					color: #555555;
				}
			}
		}
		.selectInput__indicator {
			padding: 7px 8px;
		}
  }
  .selectInput__control--is-focused, .selectInput__control--is-focused:hover {
    border-color: #cccccc;
	}
  /*.selectInput__indicator-separator {
    display: none;
  }*/
}

.customCheckbox {
	.selectIndicator {
		display: inline-block;
		vertical-align: top;
		position: relative;
		cursor: pointer;
		padding: 10px 10px 10px 0px;
		margin-right: 5px;
		&::before {
			content: " ";
			display: block;
			width: 20px;
			height: 20px;
			border: 1px solid #cccccc;
		}
		&::after {
			content: " ";
			display: block;
			position: absolute;
			top: 20px;
			left: 10px;
			width: 0px;
			height: 0px;
			background-color: #007abc;
			transition: all 200ms ease-in-out;
		}
		&.checked::after {
			top: 15px;
			left: 5px;
			width: 10px;
			height: 10px;
		}
	}
	.checkboxText {
		display: inline-block;
		line-height: 40px;
	}
}

.graph {
	display: inline-block;
	border: 1px solid #cccccc;
	border-radius: 4px;
	padding: 16px;
	vertical-align: top;
	background-color: #ffffff;
	box-shadow:
		0px 1px 3px 0px rgba(0,0,0,0.2),
		0px 1px 1px 0px rgba(0,0,0,0.14),
		0px 2px 1px -1px rgba(0,0,0,0.12);
	&:not(:last-child) {
		margin-bottom: 30px;
	}
	&.screen {
		width: calc(100%);
		.table {
			min-width: 680px;
		}
	}
	&.full {
		width: calc(100%);
		.table {
			min-width: 520px;
		}
	}
	&.half {
		width: calc(50% - 15px);
	}
	canvas {
		display: inline-block;
	}
	&.type-table {
		max-width: 100%;
		overflow-y: auto;
	}
	&.type-list {
	}
}

.graphListView {
	h4 {
		text-align: center;
		color: #666;
		margin: 5px 0px;
		font-size: 14px;
		font-weight: bold;
	}
	.graphListItem {
		border-bottom: 1px solid #eee;
		padding: 3px 0px;
    margin: 3px 0px;
		a {
			cursor: pointer;
		}
		span {
			min-width: 32px;
			float: right;
			text-align: right;
		}
		.valueLabel:first-child {
			span {
				float: left;
				text-align: left;
			}
		}
		.valueLabel:last-child {
			span:not(:last-child) {
				margin-left: 12px;
			}
		}
		.valueLabel {
			vertical-align: top;
			width: 50%;
			display: inline-block;
		}
	}
	.table {
		table-layout: auto;	
	}
}

.graphTableView {
	h4 {
		text-align: center;
		color: #333;
		margin: 5px 0px 10px;
		font-size: 18px;
		font-weight: bold;
	}
	.table {
		table-layout: auto;
		td {
			span {
				cursor: pointer;
				color: #337ab7;
				&:hover {
					color: #23527c;
					text-decoration: underline;
				}
			}
		}
	}
}

@media all and (min-width: 1250px) {
	.graph {
		&.full {
			width: calc(66.6667% - 15px);
		}
		&.half {
			width: calc(33.3333% - 15px);
		}
	}
}

@media all and (min-width: 1550px) {
	.graph {
		&.full {
			width: calc(50% - 15px);
		}
		&.half {
			width: calc(25% - 15px);
		}
	}
}

.errorMessage {
	color: #ce4844;
	display: block;
}

.trackView {
	.adminContent {
		width: calc(100% - 30px);
		margin: 0 auto;
    display: block;
	}
}

// Helpers

.loaderContainer {
  display: block;
	z-index: 101;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0px;
  background: rgba(0,0,0,0.35);
}
.loader {
  margin: 35vh auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
@-webkit-keyframes load8 {
  0% {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
  }
  100% {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
  }
  100% {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg);
  }
}

// animations

.shake-horizontal{animation:shake-horizontal .6s cubic-bezier(.455,.03,.515,.955) .0s both}

 @keyframes shake-horizontal{0%,100%{transform:translateX(0)}10%,30%,50%,70%{transform:translateX(-10px)}20%,40%,60%{transform:translateX(10px)}80%{transform:translateX(8px)}90%{transform:translateX(-8px)}}
