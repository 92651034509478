.privacyView {
	width: 100%;
	min-height: 100vh;
	text-align: center;
  background-color: #eaeff1;
  .header {
    text-align: center;
    width: 100%;
    height: 54px;
    background-color: rgb(35, 47, 62);
    box-shadow: rgb(64, 72, 84) 0px -1px 0px inset;
    .logo {
      width: 125px;
      height: 54px;
      background-size: 112px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(/img/logo.png);
      display: inline-block;
      cursor: pointer;
    }
  }
	.privacyContent {
		display: inline-block;
		max-width: 680px;
		padding: 10px 15px 25px;
		h1 {
			margin: 25px 0px;
			text-align: center;
		}
		p {
			text-align: justify;
			font-size: 16px;
		}
	}
}
